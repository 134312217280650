import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Button = (props) => (
  <Link className={`button ${props.className}`} to={props.to}>
    {props.title}
    {props.children}
  </Link>
);

Button.propTypes = {
  to: PropTypes.string
};

export default Button;
