import PropTypes from 'prop-types';
import React from 'react';

const Tag = (props) => (
  <span className='item__tag'>{props.title}</span>
);

Tag.propTypes = {
  title: PropTypes.string
};

export default Tag;