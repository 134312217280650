import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import ItemList from '../components/itemlist';
import Item from '../components/item';
import Tag from '../components/tag';
import Button from '../components/button';

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Hero title='React Architecture' description='Get inspiration to your ReactJS software architecture'>
        <Button to={'/about'} title='Why architecture is important' />
        <Button to={'/contribute'} title='Add your own' className='button--inline' />
      </Hero>
      <ItemList>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Item key={node.id} title={node.frontmatter.title} to={node.fields.slug} contributors={node.frontmatter.contributors} featuredImage={node.frontmatter.featuredImage}>
            <span className='item__tags'>
              {node.frontmatter.tags && node.frontmatter.tags.map((tag, index) => (
                <Tag key={index} title={tag} />
              ))}
            </span>
          </Item>
        ))}
      </ItemList>
    </Layout>
  );
};

export const query = graphql`
  query GetArchitectures {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: null}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            tags
            title
            contributors
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1280) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
