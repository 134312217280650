import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Img from 'gatsby-image';

const Item = (props) => {
  const contributors = props.contributors.join(', ');
  const featuredImgFluid = props.featuredImage?.childImageSharp?.fluid;

  return (
    <Link className='item' to={props.to}>
      <span className='item__image'>
        {featuredImgFluid ? <Img fluid={featuredImgFluid} alt={props.title} /> : null}
      </span>
      <span className='item__title'>{props.title}</span>
      <span className='item__contributors'>{contributors}</span>
      {props.children}

      <span className='item__cta'>Read pattern description</span>
    </Link>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.array
};

Item.defaultProps = {
  tags: []
};

export default Item;
